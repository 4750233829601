
// import React from 'react';
// import { Link } from 'react-router-dom';

// function HomePage() {
//   // Array of card data with routes
//   const cards = [
//     { title: "Merge PDF", description: "Combine PDFs in the order you want.", icon: "📎", route: "/merge-pdf" },
//     { title: "Split PDF", description: "Separate one page or a whole set.", icon: "✂️", route: "/split-pdf" },
//     { title: "Compress PDF", description: "Reduce file size while maintaining quality.", icon: "📉", route: "/compress-pdf" },
//     { title: "PDF to Word", description: "Convert PDFs to editable DOCX files.", icon: "📄", route: "/pdf-to-word" },
//     { title: "PDF to PowerPoint", description: "Turn PDFs into PPT slides.", icon: "📊", route: "/pdf-to-powerpoint" },
//     { title: "PDF to Excel", description: "Pull data from PDFs into Excel sheets.", icon: "📊", route: "/pdf-to-excel" },
//     { title: "Word to PDF", description: "Convert DOCX files to PDFs.", icon: "📃", route: "/word-to-pdf" },
//     { title: "Protect PDF", description: "Encrypt your PDFs for security.", icon: "🔒", route: "/protect-pdf" },
//     { title: "Word to PDF", description: "Convert DOCX files to PDFs.", icon: "📃", route: "/word-to-pdf" },
//     { title: "Protect PDF", description: "Encrypt your PDFs for security.", icon: "🔒", route: "/protect-pdf" },
//     // Add more cards as needed
//   ];

//   return (
//     <div className="min-h-screen bg-gray-100 p-6">
//       <h1 className="text-2xl font-bold text-gray-800 mb-6 flex  justify-center items-center">All are 100% FREE and easy to use</h1>
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-1">
//         {cards.map((card, index) => (
//           <Link 
//             to={card.route} 
//             key={index} 
//             className="bg-white shadow-lg rounded-sm p-4 hover:shadow-xl transition block"
//           >
//             <div className="flex items-center justify-center h-12 w-12 bg-blue-500 text-white rounded-md mb-4">
//               <span className="text-2xl">{card.icon}</span>
//             </div>
//             <h3 className="text-lg font-semibold text-gray-800">{card.title}</h3>
//             <p className="text-sm text-gray-600 mt-2">{card.description}</p>
//           </Link>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default HomePage;


// import React from "react";
// import { Link } from "react-router-dom";

// function HomePage() {
//   const cards = [
//     {
//       title: "Merge PDF",
//       description: "Combine PDFs in the order you want.",
//       image: "images/mp-removebg-preview.png",
//       route: "/merge-pdf",
//     },
//     {
//       title: "PDF to Word",
//       description: "Convert PDFs to editable DOCX files.",
//       image: "images/pw-removebg-preview.png",
//       route: "/pdf-to-word",
//     },
//     {
//       title: "Word to PDF",
//       description: "Convert DOCX files to PDFs.",
//       image: "images/wp.png",
//       route: "/word-to-pdf",
//     },
//     {
//       title: "Split PDF",
//       description: "Separate one page or a whole set.",
//       image: "images/sp-removebg-preview.png",
//       route: "/",
//     },
//     {
//       title: "Compress PDF",
//       description: "Reduce file size while maintaining quality.",
//       image: "images/sp-removebg-preview.png",
//       route: "/",
//     },
//     {
//       title: "PDF to PowerPoint",
//       description: "Turn PDFs into PPT slides.",
//       image: "images/sp-removebg-preview.png",
//       route: "/",
//     },
//     {
//       title: "PDF to Excel",
//       description: "Pull data from PDFs into Excel sheets.",
//       image: "images/sp-removebg-preview.png",
//       route: "/",
//     },
//     {
//       title: "Protect PDF",
//       description: "Encrypt your PDFs for security.",
//       image: "images/sp-removebg-preview.png",
//       route: "/",
//     },
//   ];

//   return (
//     <div className="min-h-screen bg-white p-6">
//       <h1 className="text-2xl font-bold text-gray-800 mb-6 flex justify-center items-center">
//         All are 100% FREE and easy to use
//       </h1>
//       <p className="text-lg text-gray-600 mb-6 flex justify-center items-center">
//         We are free, all tools..No registration or installation required. Use
//         directly online!
//       </p>
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-1">
//         {cards.map((card, index) => (
//           <Link
//             to={card.route}
//             key={index}
//              className="bg-gray-300 shadow-md rounded-sm border border-gray-200 hover:shadow-xl transform hover:-translate-y-1 transition duration-300"
//           >
//             <div className="flex justify-center">
//               <img
//                 src={card.image}
//                 alt={card.title}
//                 className="h-20 w-20 object-contain rounded-full bg-white p-2 mb-4"
//               />
//             </div>
//             <h3 className="text-center text-lg font-semibold text-black">
//               {card.title}
//             </h3>
//             <p className="text-center text-sm text-black mt-2">
//               {card.description}
//             </p>
//           </Link>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default HomePage;

import React from "react";
import { Link } from "react-router-dom";

function HomePage() {
  const cards = [
    {
      title: "Merge PDF",
      description: "Combine PDFs in the order you want.",
      image: "images/mp-removebg-preview.png",
      route: "/merge-pdf",
    },
    {
      title: "PDF to Word",
      description: "Convert PDFs to editable DOCX files.",
      image: "images/pw-removebg-preview.png",
      route: "/pdf-to-word",
    },
    {
      title: "Word to PDF",
      description: "Convert DOCX files to PDFs.",
      image: "images/wp.png",
      route: "/word-to-pdf",
    },
    {
      title: "Split PDF",
      description: "Separate one page or a whole set.",
      image: "images/sp-removebg-preview.png",
      route: "/",
    },
    {
      title: "Compress PDF",
      description: "Reduce file size while maintaining quality.",
      image: "images/sp-removebg-preview.png",
      route: "/",
    },
    {
      title: "PDF to PowerPoint",
      description: "Turn PDFs into PPT slides.",
      image: "images/sp-removebg-preview.png",
      route: "/",
    },
    {
      title: "PDF to Excel",
      description: "Pull data from PDFs into Excel sheets.",
      image: "images/sp-removebg-preview.png",
      route: "/",
    },
    {
      title: "Protect PDF",
      description: "Encrypt your PDFs for security.",
      image: "images/sp-removebg-preview.png",
      route: "/",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-white p-8">
      <h1 className="text-3xl font-extrabold text-gray-900 mb-8 text-center">
        All are 100% FREE and Easy to Use
      </h1>
      <p className="text-lg text-gray-700 mb-8 text-center max-w-2xl mx-auto">
        We are free, all tools. No registration or installation required. Use directly online!
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-1">
        {cards.map((card, index) => (
          <Link
            to={card.route}
            key={index}
            className="bg-white shadow-lg rounded-sm border hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300"
          >
            <div className="flex justify-center">
              <img
                src={card.image}
                alt={card.title}
                className="h-24 w-24 object-contain rounded-full bg-gray-100 p-3 mt-6"
              />
            </div>
            <div className="p-4 text-center">
              <h3 className="text-lg font-semibold text-gray-800">{card.title}</h3>
              <p className="text-sm text-gray-600 mt-2">{card.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default HomePage;
