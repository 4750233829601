import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import HomePage from './pages/HomePage';
import Footer from './components/Footer';
import ContactPage from './pages/ContactPage'; // Example additional page
import MergePdf from './pages/MergePdf';
import WordPdf from './pages/WordPdf';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/word-to-pdf" element={<WordPdf />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/merge-pdf" element={<MergePdf />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
