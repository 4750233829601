// api.js
import axios from "axios";

export const uploadFiles = async (files) => {
  const formData = new FormData();
  Array.from(files).forEach((file) => {
    formData.append("files", file);
  });

  try {
    const response = await axios.post("https://api.freesoftlover.xyz/word-to-pdf/v1/api/word-to-pdf", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob", // Ensure we handle the file response correctly
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
