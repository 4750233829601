// App.js
import React, { useState } from "react";
import { uploadFiles } from "../Api";

function WordPdf() {
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleUpload = async () => {
    try {
      setUploadStatus("Uploading...");
      const data = await uploadFiles(files);

      // Create a link to download the converted files
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "converted.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setUploadStatus("Upload and download successful!");
    } catch (error) {
      setUploadStatus("Upload failed: " + error.message);
    }
  };

  return (
    <div className="p-5 bg-gray-100 min-h-screen flex flex-col items-center">
      <h1 className="text-2xl font-bold mb-4 text-gray-800">Multi File Uploader</h1>
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        className="mb-4 px-4 py-2 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <button
        onClick={handleUpload}
        className="px-6 py-2 bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        Upload and Convert
      </button>
      <p className="mt-4 text-gray-600">{uploadStatus}</p>
    </div>
  );
}

export default WordPdf;
